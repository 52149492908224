import React from "react";
import QrCode from "../../Assets/images/qr.png";
import Triangle from "../../Assets/images/triangle-shape.png";
import Ring from "../../Assets/images/ring-shape.png";
import Round from "../../Assets/images/round-shape.png";
import "./ScanDownload.css";

const ScanDownload = ({ handleSetModal }) => {
  return (
    <div className="scan-download-sec">
      <div className="container">
        <div className="scan-download-bg">
          <div className="triangle-shape">
            <img src={Triangle} alt="Triangle" />
          </div>
          <div className="ring-shape">
            <img src={Ring} alt="Ring" />
          </div>
          <div className="round-shape">
            <img src={Round} alt="Round" />
          </div>
          <h2>
            Be Super Productive, Be Exam ready with <span>Kyoorious.ai</span>
          </h2>
          <button type="button" onClick={() => handleSetModal(true)}>
            <img src={QrCode} alt="QrCode" />
            <span>
              Scan and
              <br />
              Download App
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScanDownload;
