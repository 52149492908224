import { useEffect, useRef } from "react";
import "./HeroSec.css";
import { SliderArr } from "../../Utils/Constants";

const HeroSec = () => {
  const sliderRef = useRef(null);
  let requestId;

  useEffect(() => {
    const slider = sliderRef.current;
    let direction = 1;
    let position = 0;
    let speed = 0.5;
    const animate = () => {
      if (direction === 1 && position >= (SliderArr.length - 2) * 17.6) {
        // console.log('position', position);
        speed = 0.1; // Decrease speed when reaching the last two SliderArr
      } else if (direction === 1 && position < (SliderArr.length - 2) * 17.6) {
        speed = 0.5; // Normal speed
      }

      if (direction === 1 && position >= SliderArr.length * 17.6) {
        direction = -1;
        speed = 0.5;
      } else if (direction === -1 && position <= 20) {
        speed = 0.1;
        if (position <= -20) {
          direction = 1;
        }
      }
      console.log("direction", direction);
      position += direction * speed;
      slider.style.transform = `translateX(-${position}%)`;

      requestId = requestAnimationFrame(animate);
    };

    animate();

    return () => cancelAnimationFrame(requestId);
  }, [SliderArr.length]);

  return (
    <div className="hero-sec">
      <div className="container" data-aos="fade-up" data-aos-duration="1100">
        <h1 className="animated-text">
          Your <b>24X7 Study</b> Partner
        </h1>
      </div>
      <div className="slider-container">
        <div className="slider" ref={sliderRef}>
          {SliderArr &&
            SliderArr.length > 0 &&
            SliderArr.map((item, index) => (
              <div className="slide" key={index}>
                <div className="img-sec">
                  <img src={item.image} alt={item.title} />
                </div>
                <h4>{item.title}</h4>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HeroSec;
