import { useState } from 'react';
import './Faqs.css';

const Accordion = ({ title, children }) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<div className="accordion-wrapper">
			<div className={`accordion-title ${isOpen ? 'open' : ''}`} onClick={() => setOpen(!isOpen)}>
				{title}
			</div>
			<div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
				<div className="accordion-content">{children}</div>
			</div>
		</div>
	);
};

const Faqs = () => {
	return (
		<div className="container">
			<div className="faqs-sec">
				<h2>FAQs</h2>
				<div className="wrapper">
					<Accordion title="Is Kyoorious.ai reliable?">You can ask doubts in Hinglish as Kyoorious.ai understands it well.</Accordion>
					<Accordion title="Can I ask my doubts in Hinglish on the platform?">
						You can ask doubts in Hinglish as Kyoorious.ai understands it well.
					</Accordion>
					<Accordion title="Is Kyoorious.ai helpful in exam preparation?">
						You can prepare for your exams with Kyoorious.ai as it gives logical answers to your conceptual doubts, assists with
						revisions, generate exam-oriented questions and solutions to help you achieve perfect scores
					</Accordion>
					<Accordion title="Which exams, I can study on Kyoorious.ai?">
						Presently, Kyoorious.ai offers highly reliable conceptual clarity and exam practice for CBSE K9 and K10.
					</Accordion>
				</div>
			</div>
		</div>
	);
};

export default Faqs;
