import { useEffect, useState } from "react";
import AOS from 'aos';
import BenefitsCard from "./Components/BenefitsCard/BenefitsCard";
import BlogCard from "./Components/BlogCard/BlogCard";
import Faqs from "./Components/Faqs/Faqs";
import Header from "./Components/Header/Header";
import HeroSec from "./Components/HeroSec/HeroSec";
import About from "./Components/About/About";
import ScanDownload from "./Components/ScanDownload/ScanDownload";
import Footer from "./Components/Footer/Footer";
import ScanDownloadModal from "./Components/ScanDownloadModal/ScanDownloadModal";
import "./App.css";
import 'aos/dist/aos.css';

function App() {
  const [modal, setModal] = useState(false);

  const handleSetModal = (value) => {
    setModal(value);
  };
  
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    }
  }, [modal]);

  return (
    <>
      <Header handleSetModal={handleSetModal} />
      <HeroSec />
      <BenefitsCard />
      <BlogCard />
      <Faqs />
      <About />
      <ScanDownload handleSetModal={handleSetModal} />
      <Footer />
      <ScanDownloadModal modal={modal} handleSetModal={handleSetModal} />
    </>
  );
}

export default App;
