import { BenefitsCardArr } from "../../Utils/Constants";
import BenefitsShape from "../../Assets/svg/benifit-shape.svg";
import "./BenefitsCard.css";

const BenefitsCard = () => {
  return (
    <div className="benefits-sec">
      <div className="container">
        <div className="benefits-wrap">
          {BenefitsCardArr &&
            BenefitsCardArr.length > 0 &&
            BenefitsCardArr.map((item, index) => (
              <div key={index} className="benefits-card">
                <div className="benefits-icon">{item.icon}</div>
                <p className="benefits-title">{item.title}</p>
              </div>
            ))}
        </div>
      </div>
      <div className="benefit-shape">
        <img src={BenefitsShape} alt="benefit-shape" />
      </div>
    </div>
  );
};

export default BenefitsCard;
