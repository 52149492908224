import React from "react";
import ModalQr from "../../Assets/images/modal-qr.png";
import PlayStore from "../../Assets/images/play-store.png";
import AppleStore from "../../Assets/images/apple-logo.png";
import "./ScanDownloadModal.css";

const ScanDownloadModal = ({ modal, handleSetModal }) => {
  return (
    <div className={`scan-download-modal ${modal ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <button
            type="button"
            className="close-btn"
            onClick={() => handleSetModal(false)}
          >
            <svg
              width="41"
              height="42"
              viewBox="0 0 41 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.0803 1.1806C39.8102 0.908275 39.4888 0.692125 39.1348 0.544618C38.7807 0.397112 38.4009 0.321167 38.0174 0.321167C37.6338 0.321167 37.2541 0.397112 36.9 0.544618C36.5459 0.692125 36.2246 0.908275 35.9545 1.1806L20.5846 16.5796L5.21463 1.1806C4.66752 0.633489 3.92548 0.326127 3.15175 0.326127C2.37802 0.326127 1.63597 0.633489 1.08886 1.1806C0.541753 1.72771 0.234391 2.46975 0.234391 3.24348C0.234391 4.01721 0.541753 4.75926 1.08886 5.30637L16.4879 20.6763L1.08886 36.0462C0.816539 36.3163 0.600389 36.6377 0.452883 36.9917C0.305376 37.3458 0.229431 37.7256 0.229431 38.1091C0.229431 38.4927 0.305376 38.8724 0.452883 39.2265C0.600389 39.5806 0.816539 39.9019 1.08886 40.172C1.35897 40.4443 1.68031 40.6605 2.03437 40.808C2.38843 40.9555 2.76819 41.0314 3.15175 41.0314C3.5353 41.0314 3.91506 40.9555 4.26912 40.808C4.62318 40.6605 4.94453 40.4443 5.21463 40.172L20.5846 24.773L35.9545 40.172C36.2246 40.4443 36.5459 40.6605 36.9 40.808C37.2541 40.9555 37.6338 41.0314 38.0174 41.0314C38.4009 41.0314 38.7807 40.9555 39.1348 40.808C39.4888 40.6605 39.8102 40.4443 40.0803 40.172C40.3526 39.9019 40.5687 39.5806 40.7162 39.2265C40.8637 38.8724 40.9397 38.4927 40.9397 38.1091C40.9397 37.7256 40.8637 37.3458 40.7162 36.9917C40.5687 36.6377 40.3526 36.3163 40.0803 36.0462L24.6813 20.6763L40.0803 5.30637C40.3526 5.03627 40.5687 4.71492 40.7162 4.36086C40.8637 4.0068 40.9397 3.62704 40.9397 3.24348C40.9397 2.85993 40.8637 2.48017 40.7162 2.12611C40.5687 1.77205 40.3526 1.4507 40.0803 1.1806Z"
                fill="black"
              />
            </svg>
          </button>
          <h2 className="modal-title">Scan And Download</h2>
          <div className="scan-card">
            <img src={ModalQr} alt="ModalQr" />
          </div>
          <div className="store-wrap">
            <a href="#">
              <img src={PlayStore} alt="PlayStore" />
              <span>Click here for Andriod App download</span>
            </a>
            <a href="#">
              <img src={AppleStore} alt="AppleStore" />
              <span>Click here for IOS App download</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanDownloadModal;
