import React from "react";
import Logo from "../../Assets/svg/logo.svg";
import "./Header.css";

function Header({ handleSetModal }) {
  return (
    <header className="header-main">
      <div className="container">
        <div className="header-wrap">
          <div className="brand-logo">
            <a href="/">
              <img src={Logo} alt="kyoorious-ai-logo" />
            </a>
          </div>
          <div>
            <button
              className="download-btn"
              type="button"
              onClick={() => handleSetModal(true)}
            >
              <span>
                <span className="btn-text">Download App</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.32007 6.5L11.8801 3.94L14.4401 6.5"
                    stroke="black"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.8799 14.18V4.01001"
                    stroke="black"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12"
                    stroke="black"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
