import WebMock from '../../Assets/images/web-mock.png'
import './About.css';

const About = () => {
	return (
		<div className="about-sec">
			<div className="container">
				<div className="about-wrap">
					<div className="about-details">
						<h3>About</h3>
						<h2>Kyoorious.ai</h2>
						<p>
							Kyoorious.ai is a platform that harnesses cutting-edge generative AI technology to aid students achieve conceptual clarity
							and excel in exam practice, making them exam-ready.
						</p>
					</div>
					<div className="about-screen">
						<img src={WebMock} alt='WebMock' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
