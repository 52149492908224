import React from "react";
import Card1 from "../../Assets/images/card1.png";
import Card2 from "../../Assets/images/card2.png";
import Card3 from "../../Assets/images/card3.png";
import Card4 from "../../Assets/images/card4.png";
import "./BlogCard.css";

const BlogCard = () => {
  return (
    <div className="container">
      <div className="blog-card">
        <div className="card-wrapper right-text">
          <div className="card-img" data-aos="fade-up" data-aos-duration="1100">
            <img src={Card1} alt="card-1" />
          </div>
          <div className="card-details">
            <h2 data-aos="fade-up" data-aos-duration="1000">Ask Doubts</h2>
            <p data-aos="fade-up" data-aos-duration="1200">
              Generate crisp and practical answers to your subject specific
              doubts
            </p>
          </div>
        </div>
        <div className="card-wrapper left-text">
          <div className="card-details">
            <h2 data-aos="fade-up" data-aos-duration="1000">Test Yourself</h2>
            <p data-aos="fade-up" data-aos-duration="1200">
              Answer the questions to check your understanding of the concepts
            </p>
          </div>
          <div className="card-img" data-aos="fade-up" data-aos-duration="1100">
            <img src={Card2} alt="card-2" />
          </div>
        </div>
        <div className="card-wrapper right-text">
          <div className="card-img" data-aos="fade-up" data-aos-duration="1100">
            <img src={Card3} alt="card-3" />
          </div>
          <div className="card-details">
            <h2 data-aos="fade-up" data-aos-duration="1000">Questions</h2>
            <p data-aos="fade-up" data-aos-duration="1200">
              Generate exam-focused questions to enhance your exam preparation.
            </p>
          </div>
        </div>
        <div className="card-wrapper left-text">
          <div className="card-details">
            <h2 data-aos="fade-up" data-aos-duration="1000">Solutions</h2>
            <p data-aos="fade-up" data-aos-duration="1200">
              Generate exam-focused solutions to achieve perfect scores in exams
            </p>
          </div>
          <div className="card-img" data-aos="fade-up" data-aos-duration="1100">
            <img src={Card4} alt="card-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
