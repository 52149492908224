import React from "react";
import PlayStore from "../../Assets/images/play-store.png";
import AppStore from "../../Assets/images/apple-logo.png";
import Instagram from "../../Assets/images/instagram.png";
import Whatsapp from "../../Assets/images/whatsapp.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-sec">
      <div className="footer-details">
        <div>
          <p>Download App:</p>
          <a href="#">
            <img src={PlayStore} alt="PlayStore" />
          </a>
          <a href="#">
            <img src={AppStore} alt="AppStore" />
          </a>
        </div>
        <div>
          <p>Follow us:</p>
          <a href="#">
            <img src={Instagram} alt="Instagram" />
          </a>
          <a href="#">
            <img src={Whatsapp} alt="Whatsapp" />
          </a>
        </div>
      </div>
      <div className="copyright-text">
        <p>
          © Copyright 2024, All Rights Reserved by Futuristic Pedagogy Solutions
          Private Limited
        </p>
      </div>
    </div>
  );
};

export default Footer;
